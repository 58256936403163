<template>
  <div class="d-flex align-items-center">
    <div class="btn-detail-cont cursor-pointer"
         @click="openDetail()"
    >
      <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg"
           v-if="modelValue && showDetails"
      >
        <path d="M15.8213 1.07129L8.92044 7.87056C8.88191 7.90853 8.82017 7.90897 8.7811 7.87156L1.67915 1.07129"
              stroke="#979797" stroke-width="1.4" stroke-linecap="round"/>
      </svg>
      <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"
           v-else
      >
        <path d="M0.75 1L7.54927 7.90085C7.58724 7.93938 7.58768 8.00112 7.55027 8.04019L0.75 15.1421"
              stroke="#979797" stroke-width="1.4" stroke-linecap="round"/>
      </svg>
    </div>
    <slot/>
  </div>
</template>

<script>
export default {
  name: "toggleShowDetail",
  data() {
    return {
      showDetails: false
    }
  },
  props: {
    modelValue: {type: Boolean, default: () => false},
  },
  methods: {
    openDetail() {
      this.showDetails = !this.showDetails
      this.$emit('showDetails', this.showDetails);
    },
  },
  mounted() {
    this.showDetails = this.modelValue;
  },

  watch: {
    modelValue(val) {
      this.showDetails = val
    }
  }
}
</script>

<style scoped>
.btn-detail-cont {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
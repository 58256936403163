<template>
  <div :style="getStyleText">
    <money-format :cost="+cost"/>
  </div>
</template>

<script>
export default {
  name: "summaResultExecution",
  props: {
    cost: Number
  },
  computed: {
    getStyleText() {
      if (this.cost === 0) return {
        color: '#7367f0'
      }
      if (this.cost > 0) return {
        color: 'orange'
      }
      if (this.cost < 0) return {
        color: 'red'
      }
    }
  }
}
</script>

<style scoped>

</style>
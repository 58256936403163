<template>
  <div>
    <div class="mb-2 d-flex flex-wrap justify-content-end align-items-start">
      <b-button
          v-b-modal.add_contractor
          variant="outline-primary"
      >
        <feather-icon
            icon="PlusIcon"
            class="mr-50"
        />
        <span class="align-middle">Добавить контрагента</span>
      </b-button>
    </div>
    <b-table
        id="table_load_products"
        responsive
        :fields="fields"
        first-number
        last-number
        show-empty
        thead-tr-class="text-nowrap"
        :details-td-class="'p-0'"
        :items="getContractors">
      <template #head()="data">
        <div style="text-transform: none; max-width: 120px; white-space: normal">
          {{ data.field.label }}
        </div>
      </template>
      <template #cell(menu)="data">
        <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
        >
          <template v-slot:button-content>
            <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
            />
          </template>
          <b-dropdown-item
              @click="function() { fillEditContractor(data.item) }"
          >
            <feather-icon
                icon="Edit2Icon"
                class="mr-50"
            />
            <span>Править</span>
          </b-dropdown-item>
          <b-dropdown-item
              @click="function() { delContractor({contractorId: data.item.id}) }">
            <feather-icon
                icon="TrashIcon"
                class="mr-50"
            />
            <span>Удалить</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #head(summaCredit)="data">
        <div class="d-flex flex-column justify-content-between" style="height: 70px">
          <div style="text-transform: none; max-width: 120px; white-space: normal">
            Сумм денежной операции
          </div>
          <i class="type-operation">поступление</i>
        </div>
      </template>
      <template #head(summaExecCredit)="data">
        <div class="d-flex flex-column justify-content-between" style="height: 70px">
          <div style="text-transform: none; max-width: 120px; white-space: normal">
            Сумма выполнения обязательств
          </div>
          <i class="type-operation">поступление</i>
        </div>
      </template>
      <template #head(balanceCredit)="data">
        <div style="text-transform: none; max-width: 120px; white-space: normal">
          Баланс
        </div>
      </template>
      <template #head(summaDebit)="data">
        <div class="d-flex flex-column justify-content-between" style="height: 70px">
          <div style="text-transform: none; max-width: 120px; white-space: normal">
            Сумм денежной операции
          </div>
          <i class="type-operation">списание</i>
        </div>
      </template>
      <template #head(summaDebitExec)="data">
        <div class="d-flex flex-column justify-content-between" style="height: 70px">
          <div style="text-transform: none; max-width: 120px; white-space: normal">
            Сумма выполнения обязательств
          </div>
          <i class="type-operation">списание</i>
        </div>
      </template>
      <template #head(balanceDebit)="data">
        <div style="text-transform: none; max-width: 120px; white-space: normal">
          Баланс
        </div>
      </template>
      <template #head(totalBalance)="data">
        <div style="text-transform: none; max-width: 120px; white-space: normal">
          Общий баланс
        </div>
      </template>

      <template #cell(summaCredit)="data">
        <money-format
            :cost="data.item.summaCredit"
        />
      </template>
      <template #cell(summaExecCredit)="data">
        <money-format
            :cost="data.item.summaExecCredit"
        />
      </template>
      <template #cell(balanceCredit)="data">
        <money-format
            :cost="data.item.balanceCredit"
        />
      </template>
      <template #cell(summaDebit)="data">
        <money-format
            :cost="data.item.summaDebit"
        />
      </template>
      <template #cell(summaDebitExec)="data">
        <money-format
            :cost="data.item.summaDebitExec"
        />
      </template>
      <template #cell(balanceDebit)="data">
        <money-format
            :cost="data.item.balanceDebit"
        />
      </template>
      <template #cell(totalBalance)="data">
        <money-format
            :cost="data.item.totalBalance"
        />
      </template>
    </b-table>
    <b-modal
        id="add_contractor"
        hide-header
        hide-footer
        size="lg"
        centered
        v-model="showModal"
        @hide="clearCreateContractor">

      <div class="contractor-container">
        <h4 class="my-1">{{ 'Новый контрагент' }}</h4>
        <div class="name-contractor mb-1 d-flex align-items-center justify-content-between">
          <span class="font-weight-bold font-small-3 mr-1"> Имя* </span>
          <b-form-input
              class="purchase-input"
              v-model="contractorName"
              placeholder="имя контрагента"
          />
        </div>
        <div class="select-contractor-type mb-1 d-flex align-items-center justify-content-between">
          <span class="font-weight-bold font-small-3 mr-1"> Тип контрагента* </span>
          <b-form-select

              class="purchase-input"
              v-model="contractorType"
              :options="typeContractors.map(item=>({value: item, text: item}))"
          >
          </b-form-select>
        </div>
        <div class="select-contractor_country mb-1 d-flex align-items-center justify-content-between">
          <span class="font-weight-bold font-small-3 mr-1"> Страна* </span>
          <b-form-select
              class="purchase-input"
              v-model="contractorCountry"
              :options="country.map(item=>({value: item, text: item}))"
          >
          </b-form-select>
        </div>

        <div class="inn-contractor mb-1 d-flex align-items-center justify-content-between">
          <span class="font-weight-bold font-small-3 mr-1"> ИНН </span>
          <b-form-input
              class="purchase-input"
              v-model="contractorInn"
              placeholder="ИНН контрагента"
          />
        </div>
        <div class="kpp-contractor mb-1 d-flex align-items-center justify-content-between">
          <span class="font-weight-bold font-small-3 mr-1"> КПП </span>
          <b-form-input
              class="purchase-input"
              v-model="contractorKpp"
              placeholder="КПП контрагента"
          />
        </div>
        <div class="address-contractor mb-1 d-flex align-items-center justify-content-between">
          <span class="font-weight-bold font-small-3 mr-1"> Физ. адрес </span>
          <b-form-input
              class="purchase-input"
              v-model="contractorAddress"
              placeholder="фактический адрес контрагента"
          />
        </div>
        <div class="legal-address-contractor mb-1 d-flex align-items-center justify-content-between">
          <span class="font-weight-bold font-small-3 mr-1"> Юр. адрес </span>
          <b-form-input
              class="purchase-input"
              v-model="contractorLegalAddress"
              placeholder="юридический адрес контрагента"
          />
        </div>

        <div class="button-contractor d-flex">
          <b-button
              @click="addContractor"
              variant="primary"
              :disabled="!(contractorName && contractorType)"
          >
            {{ contractorId ? 'Сохранить' : 'Создать' }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {
  BTable,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormInput,
  BFormSelect,
  BModal,
} from 'bootstrap-vue';

export default {
  name: "Contractors",
  components: {
    BTable,
    BButton,
    BFormInput,
    BFormSelect,
    BModal,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Имя',
        },
        {
          key: 'type',
          label: 'Статус',
        },
        {
          key: 'inn',
          label: 'ИНН',
        },
        {
          key: 'country',
          label: 'Страна',
        },
        {
          key: 'summaCredit',
        },
        {
          key: 'summaExecCredit',
        },
        {
          key: 'balanceCredit',
        },
        {
          key: 'summaDebit',
        },
        {
          key: 'summaDebitExec',
        },
        {
          key: 'balanceDebit',
        },
        {
          key: 'totalBalance',
        },
        {
          key: 'menu',
          label: '',
        },
      ],
      showModal: false,
      contractorId: null,
      contractorName: '',
      contractorCountry: '',
      contractorType: '',
      contractorInn: '',
      contractorComment: '',
      contractorKpp: '',
      contractorAddress: '',
      contractorLegalAddress: '',
    }
  },
  computed: {
    ...mapState('purchase', ["typeContractors"]),
    ...mapState('contractors', ['contractors']),
    ...mapState('econ', ['country']),
    getContractors() {
      return this.contractors.sort((a, b) => b.id - a.id);
    }
  },
  methods: {
    ...mapActions('contractors', ['loadContractors', "createContractor", "delContractor", "updateContractor"]),

    async addContractor() {
      let values = {
        name: this.contractorName,
        address: this.contractorAddress,
        type: this.contractorType,
        inn: this.contractorInn,
        kpp: this.contractorKpp,
        comment: this.contractorComment,
        country: this.contractorCountry,
        legalAddress: this.contractorLegalAddress,
      }

      if (this.contractorId) {
        await this.updateContractor({
          contractorId: this.contractorId,
          values
        })
      } else {
        await this.createContractor(values);
      }

      this.showModal = false
      await this.loadContractors();
    },

    async fillEditContractor(row) {
      this.contractorId = row.id
      this.contractorName = row.name
      this.contractorAddress = row.address
      this.contractorType = row.type
      this.contractorInn = row.inn
      this.contractorKpp = row.kpp
      this.contractorCountry = row.country
      this.contractorComment = row.comment
      this.contractorLegalAddress = row.legal_address
      this.showModal = true
    },

    clearCreateContractor() {
      this.contractorId = null
      this.contractorName = ''
      this.contractorAddress = ''
      this.contractorType = ''
      this.contractorInn = ''
      this.contractorKpp = ''
      this.contractorComment = ''
      this.contractorCountry = ''
      this.contractorLegalAddress = ''
    }
  },

  mounted() {
    this.loadContractors();
  }
}
</script>

<style scoped>
.type-operation {
  font-size: 8px;
}
</style>
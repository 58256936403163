<template>
  <div class="table-econ">
    <div class="mb-2 d-flex flex-wrap justify-content-end align-items-start">
      <b-button
          variant="outline-primary"
          @click="addTempTransaction"
      >
        <feather-icon
            icon="PlusIcon"
            class="mr-50"
        />
        <span class="align-middle">Создать транзакцию</span>
      </b-button>
    </div>
    <b-table
        id="table_load_transaction"
        responsive
        :fields="fields"
        first-number
        last-number
        show-empty
        thead-tr-class="text-nowrap"
        :details-td-class="'p-0'"
        :items="getTransactionsItems"
        :current-page="currentPage"
        :per-page="50">

      <template #head(dateExecution)="data">
        <div style="text-align: center">
          <div>
            Дата
          </div>
          <div>
            выполн. обяз.
          </div>
        </div>
      </template>
      <template #head(summaExecution)="data">
        <div style="text-align: center">
          <div>
            Сумма
          </div>
          <div>
            выполн. обяз.
          </div>
        </div>
      </template>
      <template #head(date)="data">
        <div style="text-align: center">
          <div>
            Дата
          </div>
          <div>
            денежн. операции
          </div>
        </div>
      </template>
      <template #head(summa)="data">
        <div style="text-align: center">
          <div>
            Сумма
          </div>
          <div>
            денежн. операции
          </div>
        </div>
      </template>
      <template #cell(expenseItem)="data">
        <div style="min-width: 90px">
          <b-dropdown variant="link" no-caret style="max-height: 300px"
                      class="w-100"
                      @show="increaseZIndex(data.item.id)"
                      @hide="resetZIndex(data.item.id)"
                      menu-class="menu-list-expense-item"
                      toggle-class="button-list-expense-item text-decoration-none d-flex justify-content-between">
            <template #button-content>
              <div v-if="data.item.expenseItem" class="d-flex justify-content-between w-100"
                   :title="data.item.expenseItem"
                   :class="data.item.typeAction === 'поступление' ? 'text-success' : null">
                <div style="width: 150px; overflow: hidden; text-overflow: ellipsis; text-align: start"> {{
                    data.item.expenseItem
                  }}
                </div>
                <feather-icon
                    class="ml-1"
                    icon="ChevronDownIcon"/>
              </div>
              <div v-else class="text-secondary font-italic d-flex justify-content-between">
                выбрать статью
              </div>
            </template>
            <template #default>
              <div class="position-relative h-100">
                <div class="sticky-top px-1 d-flex bg-white">
                  <b-form-input
                      size="sm"
                      v-model="filterExpNames"
                      placeholder="Название статьи"
                  />
                  <b-button variant="outline-primary"
                            class="ml-1"
                            size="sm"
                            v-b-modal.add_transaction
                            @click="currentPopUp='expenseItem'"
                  >
                    <feather-icon
                        icon="PlusIcon"
                    />
                  </b-button>
                </div>
                <b-dropdown-item v-for="(exp, i) in getExpNames"
                                 :key="i"
                                 @click="function() { if (data.item.id) updateTransaction({id: data.item.id, expenseItem: exp.name});
                                 else {
                                   createTransaction({item: data.item, field: 'expenseItem', value: exp.name})}
                                 }">
                  {{ exp.name }}
                </b-dropdown-item>
              </div>
            </template>
          </b-dropdown>
        </div>
      </template>
      <template #cell(contractor)="data">
        <div style="min-width: 90px">
          <b-dropdown v-if="data.item.expenseItem" variant="link" no-caret style="max-height: 300px"
                      class="w-100"
                      menu-class="menu-list-expense-item"
                      toggle-class="text-primary  button-list-expense-item-contractor button-list-expense-item text-decoration-none d-flex justify-content-between">
            <template #button-content>
              <div v-if="data.item.contractor">
                {{ data.item.contractor }}
                <feather-icon
                    class="ml-1"
                    icon="ChevronDownIcon"/>
              </div>
              <div v-else class="text-secondary font-italic">
                выбрать контрагента
              </div>
            </template>
            <template #default>
              <div class="position-relative h-100">
                <div class="sticky-top px-1 d-flex bg-white">
                  <b-form-input
                      size="sm"
                      v-model="filterContractors"
                      placeholder="Имя контрагента"
                  />
                  <b-button variant="outline-primary"
                            class="ml-1"
                            size="sm"
                            v-b-modal.add_transaction
                            @click="currentPopUp='contractor'"
                  >
                    <feather-icon
                        icon="PlusIcon"
                    />
                  </b-button>
                </div>
                <b-dropdown-item v-for="(exp, i) in getContractors"
                                 :key="i"
                                 @click="function() { if (data.item.id) updateTransaction({id: data.item.id, contractorId: exp.id});
                                 else {
                                   data.item.contractorId = exp.id
                                   createTransaction({item: data.item, field: 'contractor', value: exp.name})}
                                 }"

                >
                  {{ exp.name }}
                </b-dropdown-item>
              </div>
            </template>
          </b-dropdown>
        </div>
      </template>
      <template #cell(finAccount)="data">
        <div style="min-width: 90px; width: 100%">
          <b-dropdown v-if="data.item.contractor" variant="link" no-caret
                      style="max-height: 300px; width: 100%; "
                      menu-class="menu-list-expense-item"
                      toggle-class="text-primary  button-list-expense-item-contractor button-list-expense-item text-decoration-none d-flex justify-content-between"
          >
            <template #button-content>
              <div v-if="data.item.finAccount">
                {{ data.item.finAccount }}
                <feather-icon
                    class="ml-1"
                    icon="ChevronDownIcon"
                />
              </div>
              <div v-else class="text-secondary font-italic">
                выбрать счет
              </div>
            </template>
            <template #default>
              <div class="position-relative h-100">
                <div class="sticky-top px-1 d-flex bg-white">
                  <b-button variant="outline-primary"
                            class="w-100"
                            size="sm"
                            v-b-modal.add_transaction
                            @click="currentPopUp='finAccount'"
                  >
                    <feather-icon
                        icon="PlusIcon"
                    />
                  </b-button>
                </div>
                <b-dropdown-item v-for="(item, i) in finAccounts"
                                 :key="i"
                                 @click="function() { if (data.item.id) updateTransaction({id: data.item.id, finAccountId: item.id});
                                 else {
                                   data.item.finAccountId=item.id
                                   createTransaction({item: data.item, field: 'finAccount', value: `${item.account_number_short} ${item.account_name}`})}
                                 }"

                > {{ `${item.account_number_short} ${item.account_name}` }}
                </b-dropdown-item>
              </div>
            </template>
          </b-dropdown>
        </div>
      </template>
      <template #cell(date)="data">
        <div style="min-width: 90px">
          <b-dropdown v-if="data.item.finAccount" variant="link" no-caret
                      menu-class="p-0 m-0"
                      toggle-class="p-0 m-0"
          >
            <template #button-content>
              <div class="w-100 h-100 d-flex justify-content-between align-items-center">
                <feather-icon
                    class="mr-1"
                    size="18"
                    icon="CalendarIcon"/>
                <div v-if="data.item.date" class="date-create-tr">{{ formatDate(data.item.date) }}</div>
                <div class="date-create-tr text-danger" v-else>- - -</div>
              </div>
            </template>
            <b-dropdown-form form-class="m-0 p-0">

              <b-calendar :value="data.item.date"
                          @selected="function(date) {
                           if (data.item.id) updateDate({id: data.item.id, dateContext: {selectedYMD: date}});
                           else createTransaction({item: data.item, field: 'date', value: date})
                          }"
                          locale="ru">
              </b-calendar>

            </b-dropdown-form>
          </b-dropdown>
        </div>
      </template>
      <template #cell(dateExecution)="data">
        <div style="min-width: 90px">
          <b-dropdown v-if="data.item.date" variant="link" no-caret
                      menu-class="p-0 m-0"
                      toggle-class="p-0 m-0"
          >
            <template #button-content>
              <div class="w-100 h-100 d-flex justify-content-between align-items-center">
                <feather-icon
                    class="mr-1"
                    size="18"
                    icon="CalendarIcon"/>
                <div v-if="data.item.dateExecution" class="date-create-tr">{{
                    formatDate(data.item.dateExecution)
                  }}
                </div>
                <div class="date-create-tr  text-danger" v-else>- - -</div>
              </div>
            </template>
            <b-dropdown-form form-class="m-0 p-0">
              <b-calendar :value="data.item.dateExecution"
                          @selected="function(date) {
                           if (data.item.id) updateDateExecution({id: data.item.id, dateContext: {selectedYMD: date}});
                           else createTransaction({item: data.item, field: 'dateExecution', value: date});
                          }" locale="ru">
              </b-calendar>
            </b-dropdown-form>
          </b-dropdown>
        </div>
      </template>
      <template #cell(summa)="data" class="trans">
        <div v-if="data.item.date" style="min-width: 110px; cursor: text"
             @click="editSumma = data.index">
          <b-form-input
              v-if="editSumma === data.index"
              :value="data.item.summa"
              autofocus
              ref="summa"
              type="number"
              @keyup.enter="$refs.summa.blur()"
              @blur="function(ev) {
                  if (data.item.id) updateSumma({id: data.item.id, summa: ev.target.value});
                  else createTransaction({item: data.item, field: 'summa', value: ev.target.value});
              }"
          />
          <div v-else :class="data.item.typeAction === 'поступление' ? 'text-success' : null">
            <money-format :cost="+data.item.summa"/>
          </div>
        </div>
      </template>
      <template #cell(summaExecution)="data">
        <div v-if="data.item.summa" style="min-width: 110px; cursor: text"
             @click="summaExecution = data.index">
          <b-form-input
              v-if="summaExecution === data.index"
              :value="data.item.summaExecution"
              autofocus
              type="number"
              ref="summaExecution"
              @keyup.enter="$refs.summaExecution.blur()"
              @blur="function(ev) {
                  if (data.item.id) updateSummaExecution({id: data.item.id, summaExecution: ev.target.value});
                  else createTransaction({item: data.item, field: 'summaExecution', value: ev.target.value});
              }"
          />
          <money-format
              v-else
              :cost="+data.item.summaExecution"/>
        </div>
      </template>
      <template #cell(purchaseName)="data">
        <div v-if="data.item.date" style="min-width: 90px; width: 100%">
          <b-dropdown variant="link" no-caret style="max-height: 300px; width: 100%"
                      menu-class="menu-list-expense-item"
                      toggle-class="text-primary  button-list-expense-item-contractor button-list-expense-item text-decoration-none d-flex justify-content-end"
          >
            <template #button-content>
              {{ data.item.purchaseName }}
              <feather-icon
                  class="ml-1"
                  icon="ChevronDownIcon"/>
            </template>
            <b-dropdown-item v-for="(purchase, i) in purchases"
                             :key="i"
                             @click="function() {
                               addPurchaseToTransaction({id: data.item.id, purchaseName: purchase.name, purchaseId: purchase.id})
                             }"
            >
              {{ purchase.name }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template #cell(comment)="data">
        <b-dropdown
            v-if="data.item.comment "
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
        >
          <template v-slot:button-content>
            <feather-icon
                icon="MessageSquareIcon"
                size="16"
                class="text-body align-middle mr-25"
            />
          </template>
          <b-dropdown-form>
            <div style="max-width: 360px; width: 250px" class="text-wrap">
              {{ data.item.comment }}
            </div>
          </b-dropdown-form>
        </b-dropdown>
      </template>
      <template #cell(summaResult)="data">
        <summa-result-execution v-if="data.item.summa"
                                :cost="data.item.typeAction === 'списание' ? data.item.summa - data.item.summaExecution : data.item.summaExecution -data.item.summa"/>
      </template>
      <template #cell(menu)="data">
        <feather-icon
            icon="TrashIcon"
            class="cursor-pointer"
            color="primary"
            @click="function() { delTransaction(data.item.id) }"
        />
      </template>
    </b-table>
    <b-modal
        id="add_transaction"
        hide-header
        hide-footer
        size="lg"
        centered
        v-model="showModal"
        @hide="clearCreatePurchase"
    >
      <transaction-create
          ref="transaction-creator"
          :editTransaction="selectEditPurchase"
          :form-type="currentPopUp"
      />
    </b-modal>
  </div>
</template>

<script>

import {
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
  BPopover,
  BInputGroup,
  BDropdownForm,
  BInputGroupAppend,
  BFormSelect,
  BCalendar,
  BFormInput
} from 'bootstrap-vue';

import {mapActions, mapState} from "vuex";
import transactionCreate from "@/components/econ/TransactionCreate";
import summaResultExecution from "@/components/econ/summaResultExecution";

export default {
  name: "TableEcon",
  components: {
    BButton,
    BTable,
    BDropdown,
    summaResultExecution,
    BDropdownItem,
    BDropdownForm,
    BFormInput,
    BInputGroupAppend,
    transactionCreate,
    BPopover,
    BCalendar,
    BFormDatepicker,
    BInputGroup,
    BFormSelect
  },
  data() {
    return {
      editSumma: null,
      summaExecution: null,
      currentPopUp: '',
      filterExpNames: '',
      filterContractors: '',
      fields: [
        {
          key: 'expenseItem',
          label: 'Статья',
          stickyColumn: true,
          tdAttr: function (value, key, item) {
            if (key === 'expenseItem') return {
              'id': `expenseItem_td_${item.id}`
            }
          },
          thClass: 'transform-none'
        },
        {
          key: 'contractor',
          label: 'Контрагент',
          thClass: 'transform-none'
        },
        {
          key: 'finAccount',
          label: 'Счет',
          thClass: 'transform-none'
        },
        {
          key: 'date',
          label: 'Дата денежной операции',
          thClass: 'transform-none'
        },
        {
          key: 'summa',
          label: 'Сумма денежной операции',
          thClass: 'transform-none'
        },
        {
          key: 'dateExecution',
          thClass: 'transform-none'
        },
        {
          key: 'summaExecution',
          thClass: 'transform-none'
        },
        {
          key: 'summaResult',
          label: 'Обязательства',
          thClass: 'transform-none'
        },
        {
          key: 'purchaseName',
          label: 'Закупка',
          thClass: 'transform-none'
        },
        {
          key: 'comment',
          label: 'Коммент',
          thClass: 'transform-none'
        },
        {
          key: 'menu',
          label: '',
          thClass: 'transform-none'
        },
      ],
      selectEditPurchase: null,
      selectedExpenseItems: '',
      currentPage: 1,
      showModal: false
    }
  },

  methods: {
    clearCreatePurchase() {
      this.selectEditPurchase = null;
    },

    resetZIndex(index) {
      const td = document.querySelector(`#expenseItem_td_${index}`);
      td.style.zIndex = 2;
    },

    increaseZIndex(index) {
      const td = document.querySelector(`#expenseItem_td_${index}`);
      td.style.zIndex = 9;
    },

    async updateSumma({id, summa}) {
      await this.updateTransaction({id, summa});
      this.editSumma = null;
    },

    async updateSummaExecution({id, summaExecution}) {
      await this.updateTransaction({id, summaExecution});
      this.summaExecution = null;
    },

    async addPurchaseToTransaction({id, purchaseId}) {
      await this.updateTransaction({id, procurementId: purchaseId})
    },

    async delTransaction(id) {
      if (id === null) await this.cancelCreate()
      await this.del({id})
    },

    async updateDate({id, dateContext}) {
      await this.updateTransaction({id, date: dateContext.selectedYMD});
    },

    async updateDateExecution({id, dateContext}) {
      await this.updateTransaction({id, dateReceipt: dateContext.selectedYMD});
    },

    async createTransaction({item, field, value}) {
      if (typeof value === 'string') value.trim();
      item[field] = value
      if (item.summa || item.summaExecution) {
        this.editSumma = null;
        await this.create({
          expenseItem: item.expenseItem,
          contractorId: item.contractorId,
          finAccountId: item.finAccountId,
          date: item.date,
          dateReceipt: item.dateExecution,
          summa: item.summa,
        })
      }
    },
    formatDate(dateString) {
      const months = ['янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];
      const [year, month, day] = dateString.split('-');
      const formattedMonth = months[parseInt(month) - 1];
      const formattedYear = year.slice(-2);
      return `${day} ${formattedMonth}. ${formattedYear}`;
    },

    getDate(date) {
      if (date) {
        let date = new Date(date);
        let year = date.getUTCFullYear();
        let month = String(date.getUTCMonth() + 1).padStart(2, '0');
        let day = String(date.getUTCDate()).padStart(2, '0');
        return `${day}.${month}.${year}`;
      }
      return ''
    },

    ...mapActions('econ', ["getTransactions", "getCatalogs", "del", "create", "updateTransaction", "addTempTransaction", "cancelCreate", "create"]),
  },

  computed: {
    getTransactionsItems() {
      return this.transactions
    },

    getExpNames() {
      return this.expenseItems.filter(item => {
        if (item.name === null ||
            item.name.toLowerCase().includes(this.filterExpNames.toLowerCase())) return item
      })
    },

    getContractors() {
      return this.contractors.filter(item => item.name.toLowerCase().includes(this.filterContractors.toLowerCase()))
    },

    ...mapState('econ', ['transactions', "expenseItems", "finAccounts"]),
    ...mapState('purchase', ["contractors", 'purchases']),
  },

  watch: {},

  async mounted() {
    await this.getTransactions()
    await this.getCatalogs()
  },
}
</script>

<style>
.button-edit-purchase {
  outline: none;
  border: none;
  background: none;
}

.button-set-status {
  padding: 0;
}

.menu-list-expense-item {
  max-height: 300px;
  overflow-y: auto;
}

.button-list-expense-item {
  width: 100%;
  padding: 0 !important;
}


.button-list-expense-item-contractor {
  color: #4e5154 !important;
  font-weight: normal
}

.date-create-tr {
  color: #4e5154;
  font-weight: normal
}

.transform-none {
  text-transform: none !important;
  vertical-align: middle !important;
}

.button-set-status::after {
  content: none;
}


</style>
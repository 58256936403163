<template>
  <div class="main-create-container">
    <div v-if="formType === 'finAccount'" class="warehouse-container">
      <h4 class="my-1">{{ 'Новый счет' }}</h4>
      <div class="input-name-account mb-1 d-flex align-items-center justify-content-between">
        <span class="text-nowrap font-weight-bold font-small-3 mr-1"> Имя счета* </span>
        <b-form-input
            v-model="finAccountName"
            placeholder="имя фин. орг."
        />
      </div>
      <div class="input-number-account mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1"> Последние 4 цифры счета* </span>
        <b-form-input
            v-model="finAccountNumber"
            placeholder="7984"
        />
      </div>
      <div class="button-contractor d-flex justify-content-between">
        <b-button
            @click="$bvModal.hide('add_transaction')"
            variant="outline-primary"
        >
          Отмена
        </b-button>
        <b-button
            @click="addFinAccount"
            variant="primary"
        >
          Сохранить
        </b-button>
      </div>
    </div>
    <div v-if="formType === 'expenseItem'" class="expense-container">
      <h4 class="my-1">{{ 'Новая статья' }}</h4>
      <div class="input-expense mb-1 d-flex align-items-center justify-content-between">
        <span class="text-nowrap font-weight-bold font-small-3 mr-1"> Название статьи* </span>
        <b-form-input
            v-model="expenseItem"
            placeholder="Название статьи"/>
      </div>
      <div class="input-expense mb-1 d-flex align-items-center justify-content-between">
        <span class="text-nowrap font-weight-bold font-small-3 mr-1"> Описание статьи </span>
        <b-form-textarea
            v-model="expenseDescription"
            placeholder="Описание статьи"
        />
      </div>
      <div class="button-contractor d-flex justify-content-between">
        <b-button
            @click="$bvModal.hide('add_transaction')"
            variant="outline-primary"
        >
          Отмена
        </b-button>
        <b-button
            @click="addExpenseItem"
            variant="primary"
        >
          Сохранить
        </b-button>
      </div>
    </div>
    <div v-if="formType === 'contractor'" class="contractor-container">
      <h4 class="my-1">{{ 'Новый контрагент' }}</h4>
      <div class="name-contractor mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1"> Имя* </span>
        <b-form-input
            class="transaction-input"
            v-model="contractorName"
            placeholder="имя контрагента"
        />
      </div>
      <div class="select-contractor-type mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1"> Тип контрагента* </span>
        <b-form-select

            class="transaction-input"
            v-model="contractorType"
            :options="typeContractors.map(item=>({value: item, text: item}))"
        >
        </b-form-select>
      </div>

      <div class="inn-contractor mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1"> ИНН </span>
        <b-form-input
            class="transaction-input"
            v-model="contractorInn"
            placeholder="ИНН контрагента"
        />
      </div>
      <div class="kpp-contractor mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1"> КПП </span>
        <b-form-input
            class="transaction-input"
            v-model="contractorKpp"
            placeholder="КПП контрагента"
        />
      </div>
      <div class="select-country mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1"> Страна* </span>
        <b-form-select
            class="transaction-input"
            v-model="contractorCountries"
            :options="countries.map(item=>({value: item, text: item}))"
        >
        </b-form-select>
      </div>
      <div class="address-contractor mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1"> Физ. адрес </span>
        <b-form-input
            class="transaction-input"
            v-model="addressContractor"
            placeholder="фактический адрес контрагента"
        />
      </div>
      <div class="legal-address-contractor mb-1 d-flex align-items-center justify-content-between">
        <span class="font-weight-bold font-small-3 mr-1"> Юр. адрес </span>
        <b-form-input
            class="transaction-input"
            v-model="legalContractorAddress"
            placeholder="юридический адрес контрагента"
        />
      </div>

      <div class="button-contractor d-flex justify-content-between">
        <b-button
            @click="$bvModal.hide('add_transaction')"
            variant="outline-primary"
        >
          Отмена
        </b-button>
        <b-button
            @click="createContractor"
            variant="primary"
            :disabled="!(contractorName && contractorType)"
        >
          Создать
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {VBPopover} from "bootstrap-vue";
import {
  BButton,
  BFormInput,
  BFormSelect,
  BFormDatepicker,
  BFormTextarea
} from 'bootstrap-vue';
import {mapActions, mapState} from "vuex";

export default {
  name: "TransactionCreate",
  components: {
    BButton,
    BFormSelect,
    BFormDatepicker,
    BFormTextarea,
    BFormInput,
  },

  data() {
    return {
      stateWarehouseWrite: null,
      contractorType: '',
      current: 'transaction',
      transactionId: '',
      transactionSumma: '',
      contractorCountries: '',
      executionSumma: '',
      transactionFinAccount: '',
      transactionDate: '',
      dateReceipt: '',
      transactionContractorName: '',
      comment: '',
      purchaseName: '',
      finAccountName: '',
      finAccountNumber: '',
      selectedExpenseItems: '',
      expenseDescription: '',
      expenseItem: '',
      warehouseName: '',
      contractorWarehouse: '',
      contractorName: '',
      addressContractor: '',
      contractorInn: '',
      legalContractorAddress: '',
      contractorKpp: '',
      addressWarehouse: ''
    }
  },

  props: {
    formType: String,
    editTransaction: Object
  },

  directives: {
    'b-popover': VBPopover
  },
  computed: {
    ...mapState('econ', ['expenseItems', "finAccounts", "transactions", "expenseItems"]),

    ...mapState('user', ["countries"]),
    ...mapState('purchase', ['contractors', "warehouses", "typeContractors", 'purchases']),
  },

  mounted() {
    console.log('mounted', this.name);
    if (this.editTransaction) {
      this.transactionId = this.editTransaction.id;
      this.transactionSumma = this.editTransaction.summa;
      this.executionSumma = this.editTransaction.summaExecution;
      this.transactionFinAccount = this.editTransaction.finAccount;
      this.transactionDate = this.editTransaction.date;
      this.dateReceipt = this.editTransaction.dateExecution;
      this.transactionContractorName = this.editTransaction.contractor;
      this.comment = this.editTransaction.comment;
      this.purchaseName = this.editTransaction.purchaseName;
      this.finAccountName = this.editTransaction.finAccountName;
      this.finAccountNumber = this.editTransaction.finAccountNumber;
      this.selectedExpenseItems = this.editTransaction.expenseItem;
      this.expenseDescription = this.editTransaction.expenseDescription;
    }
  },

  methods: {
    async createContractor() {
      this.stateContractor = 0;
      this.stateContractor = await this.addContractor({
        name: this.contractorName,
        country: this.contractorCountries,
        address: this.addressContractor,
        legalAddress: this.legalContractorAddress,
        inn: this.contractorInn,
        kpp: this.contractorKpp,
        type: this.contractorType
      })
      this.$bvModal.hide('add_transaction');
    },

    async addFinAccount() {
      if (this.finAccountName && this.finAccountNumber) {
        let res = await this.createFinAccount({accountName: this.finAccountName, accountNumber: this.finAccountNumber});
        this.$bvModal.hide('add_transaction');
      }
    },

    async addExpenseItem() {
      if (this.expenseItem && this.expenseDescription) {
        let res = await this.createExpenseItem({name: this.expenseItem, description: this.expenseDescription});
        this.$bvModal.hide('add_transaction');
      }
    },

    async createTransaction() {
      if (this.selectedExpenseItems && this.transactionContractorName && this.transactionFinAccount && this.transactionDate && this.transactionSumma) {
        await this.create({
          expenseItem: this.selectedExpenseItems,
          contractorId: this.contractors.find(item => item.name === this.transactionContractorName)?.id,
          finAccountId: this.finAccounts.find(item => `${item.account_number_short} ${item.account_name}` === this.transactionFinAccount)?.id,
          procurementId: this.purchases.find(item => item.name === this.purchaseName)?.id,
          date: this.transactionDate,
          dateReceipt: this.dateReceipt,
          summa: this.transactionSumma,
          summaExecution: this.executionSumma,
          comment: this.comment
        });
        this.$bvModal.hide('add_transaction')
      }
    },

    async saveTransaction() {
      this.$bvModal.hide('add_transaction');
      if (this.editTransaction.id) {
        await this.updateTransaction({
          id: this.editTransaction.id,
          expenseItem: this.selectedExpenseItems,
          contractorId: this.contractors.find(item => item.name === this.transactionContractorName)?.id,
          finAccountId: this.finAccounts.find(item => `${item.account_number_short} ${item.account_name}` === this.transactionFinAccount)?.id,
          procurementId: this.purchases.find(item => item.name === this.purchaseName)?.id,
          date: this.transactionDate,
          dateReceipt: this.dateReceipt,
          summa: this.transactionSumma,
          summaExecution: this.executionSumma,
          comment: this.comment
        });
      }
    },

    ...mapActions('purchase', ["addContractor", "addWarehouse", "createPurchase"]),
    ...mapActions('econ', ["createFinAccount", "createExpenseItem", "create", "updateTransaction"])
  }

}
</script>

<style scoped>

.date-order {
  text-align: center;
}

.transaction-input {
  max-width: 580px;
}

.order-status-icon {

}

</style>
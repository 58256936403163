<template>
  <b-card>
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="ShoppingCartIcon"/>
          <span>Закупки</span>
        </template>
        <TablePurchase/>
      </b-tab>
      <b-tab class="table-orders-tab">
        <template #title>
          <feather-icon icon="ListIcon"/>
          <span>Заказы</span>
        </template>
        <TableOrders/>
      </b-tab>
      <b-tab title>
        <template #title>
          <feather-icon icon="UsersIcon"/>
          <span>Контрагенты</span>
        </template>
        <Contractors/>
      </b-tab>
      <b-tab title>
        <template #title>
          <feather-icon icon="TrendingUpIcon"/>
          <span>Экономика</span>
        </template>
        <TableEcon/>
      </b-tab>
      <b-tab title>
        <template #title>
          <feather-icon icon="TrendingUpIcon"/>
          <span>CF</span>
        </template>
        <TableCF/>
      </b-tab>
      <b-tab title>
        <template #title>
          <feather-icon icon="TrendingUpIcon"/>
          <span>PNL</span>
        </template>
        <TablePNL/>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>

import {
  BButton,
  BCard,
  BCol,
  BTabs,
  BTab,
  BCardText,
  BModal,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BRow,
  VBModal,
} from 'bootstrap-vue';

import {mapActions, mapState} from "vuex";
import tableLoadProducts from "@/components/product/tableLoadProducts";
import detailProducts from "@/components/product/detailProducts";
import TableOrders from "@/components/procurement/TableOrders";
import TablePurchase from "@/components/procurement/TablePurchase";
import TableEcon from "@/components/econ/TableEcon";
import TableCF from "@/components/CF/TableCF";
import Contractors from "@/components/Contractors";
import TablePNL from "@/components/PNL/TablePNL";
import loadProductsFromMp from "@/components/product/LoadProductsFromMp";

export default {
  name: "Procurement",

  data() {
    return {
      findFilter: '',
      brandFilter: '',
      objectFilter: '',
      currentDetailID: null,
      show: false
    }
  },

  components: {
    BCard,
    BTabs,
    Contractors,
    BTab,
    BCardText,
    BButton,
    BCol,
    BModal,
    BRow,
    BFormInput,
    TableCF,
    TablePNL,
    BFormGroup,
    BFormSelect,
    TableOrders,
    tableLoadProducts,
    TableEcon,
    detailProducts,
    loadProductsFromMp,
    TablePurchase
  },

  directives: {
    'b-modal': VBModal
  },

  methods: {
    ...mapActions('products', ["reloadNomenclaturesAll"]),
    ...mapActions('orders', ["clear"]),
    ...mapActions('purchase', ["loadListPurchase", "loadCatalogs"]),
    ...mapActions('user', ["loadCountries"]),
    showDetails(id) {
      this.currentDetailID = id;
      this.show = true
    },
  },

  computed: {
    ...mapState('products', ['listAllCategories', "listAllBrands", "productsWidthWB", "selectedProductsId"]),
  },

  mounted() {
    this.loadListPurchase();
    this.loadCatalogs();
    this.loadCountries();
  },

  unmounted() {
    this.clear();
  }
}
</script>

<style scoped>

</style>
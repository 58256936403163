<template>
  <div class="table-cf">
    <b-table
        id="table_cf"
        responsive
        :fields="fields"
        thead-tr-class="thead-cf"
        table-class="table-all-cf"
        :items="getGroupedTransactionsTop"
    >
      <template #head(title)="data">
        <b-dropdown variant="link" no-caret
                    menu-class="p-0 m-0"
                    toggle-class="p-0 m-0"
        >
          <template #button-content>
            <toggle-show-detail>
              {{ currentYear }}
            </toggle-show-detail>
          </template>
          <b-dropdown-item
              v-for="year of transactionsYears"
              :key="year"
              @click="currentYear = +year"
          >
            {{ year }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #head(jan)="data">
        <div class="border-bottom text-center">
          {{ data.field.label }}
        </div>
        <div class="text-center font-weight-normal">
          {{ getMonthNum(data.field.key) }}
        </div>
      </template>
      <template #head(feb)="data">
        <div class="border-bottom text-center">
          {{ data.field.label }}
        </div>
        <div class=" text-center font-weight-normal">
          {{ getMonthNum(data.field.key) }}
        </div>
      </template>
      <template #head(mar)="data">
        <div class="border-bottom text-center">
          {{ data.field.label }}
        </div>
        <div class=" text-center font-weight-normal">
          {{ getMonthNum(data.field.key) }}
        </div>
      </template>
      <template #head(apr)="data">
        <div class="border-bottom text-center">
          {{ data.field.label }}
        </div>
        <div class=" text-center font-weight-normal">
          {{ getMonthNum(data.field.key) }}
        </div>
      </template>
      <template #head(may)="data">
        <div class="border-bottom text-center">
          {{ data.field.label }}
        </div>
        <div class=" text-center font-weight-normal">
          {{ getMonthNum(data.field.key) }}
        </div>
      </template>
      <template #head(jun)="data">
        <div class="border-bottom text-center">
          {{ data.field.label }}
        </div>
        <div class=" text-center font-weight-normal">
          {{ getMonthNum(data.field.key) }}
        </div>
      </template>
      <template #head(jul)="data">
        <div class="border-bottom text-center">
          {{ data.field.label }}
        </div>
        <div class=" text-center font-weight-normal">
          {{ getMonthNum(data.field.key) }}
        </div>
      </template>
      <template #head(aug)="data">
        <div class="border-bottom text-center">
          {{ data.field.label }}
        </div>
        <div class=" text-center font-weight-normal">
          {{ getMonthNum(data.field.key) }}
        </div>
      </template>
      <template #head(sep)="data">
        <div class="border-bottom text-center">
          {{ data.field.label }}
        </div>
        <div class=" text-center font-weight-normal">
          {{ getMonthNum(data.field.key) }}
        </div>
      </template>
      <template #head(oct)="data">
        <div class="border-bottom text-center">
          {{ data.field.label }}
        </div>
        <div class=" text-center font-weight-normal">
          {{ getMonthNum(data.field.key) }}
        </div>
      </template>
      <template #head(nov)="data">
        <div class="border-bottom text-center">
          {{ data.field.label }}
        </div>
        <div class=" text-center font-weight-normal">
          {{ getMonthNum(data.field.key) }}
        </div>
      </template>
      <template #head(dec)="data">
        <div class="border-bottom text-center">
          {{ data.field.label }}
        </div>
        <div class=" text-center font-weight-normal">
          {{ getMonthNum(data.field.key) }}
        </div>
      </template>
      <template #cell(title)="data">
        <div style="">
          <div v-if="data.item.levelGroup === 'bottom'"
               class="detail-group-bottom"
          >
            {{ data.item.title }}
          </div>
          <toggle-show-detail
              v-else-if="'showDetails' in data.item"
              :modelValue="data.item.showDetails"
              @showDetails="(val)=>{addDetailRows(val, data.item); data.item.showDetails = val}"
          >
            {{ data.item.title }}
          </toggle-show-detail>
          <div v-else style="margin-left: 8px">
            {{ data.item.title }}
          </div>
        </div>
      </template>
      <template #cell(jan)="data">
        <div style="">
          <money-format
              :roundMethod="'round'"
              :cost="+data.item.jan"/>
        </div>
      </template>
      <template #cell(feb)="data">
        <div style="">
          <money-format
              :roundMethod="'round'"
              :cost="+data.item.feb"/>
        </div>
      </template>
      <template #cell(mar)="data">
        <div style="">
          <money-format
              :roundMethod="'round'"
              :cost="+data.item.mar"/>
        </div>
      </template>
      <template #cell(apr)="data">
        <div style="">
          <money-format
              :roundMethod="'round'"
              :cost="+data.item.apr"/>
        </div>
      </template>
      <template #cell(may)="data">
        <div style="">
          <money-format
              :roundMethod="'round'"
              :cost="+data.item.may"/>
        </div>
      </template>
      <template #cell(jun)="data">
        <div style="">
          <money-format
              :roundMethod="'round'"
              :cost="+data.item.jun"/>
        </div>
      </template>
      <template #cell(jul)="data">
        <div style="">
          <money-format
              :roundMethod="'round'"
              :cost="+data.item.jul"/>
        </div>
      </template>
      <template #cell(aug)="data">
        <div style="">
          <money-format
              :roundMethod="'round'"
              :cost="+data.item.aug"/>
        </div>
      </template>
      <template #cell(sep)="data">
        <div style="">
          <money-format
              :roundMethod="'round'"
              :cost="+data.item.sep"/>
        </div>
      </template>
      <template #cell(oct)="data">
        <div style="">
          <money-format
              :roundMethod="'round'"
              :cost="+data.item.oct"/>
        </div>
      </template>
      <template #cell(nov)="data">
        <div style="">
          <money-format
              :roundMethod="'round'"
              :cost="+data.item.nov"/>
        </div>
      </template>
      <template #cell(dec)="data">
        <div style="">
          <money-format
              :roundMethod="'round'"
              :cost="+data.item.dec"/>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>

import {
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
  BPopover,
  BInputGroup,
  BDropdownForm,
  BInputGroupAppend,
  BFormSelect,
  BCalendar,
  BFormInput
} from 'bootstrap-vue';

import {mapActions, mapState} from "vuex";
import transactionCreate from "@/components/econ/TransactionCreate";
import summaResultExecution from "@/components/econ/summaResultExecution";
import TableDetailStartMonthTran from "@/components/CF/TableDetailStartMonthTran";
import toggleShowDetail from "@/components/toggleShowDetail";

export default {
  name: "TablePNL",
  components: {
    BButton,
    BTable,
    BDropdown,
    summaResultExecution,
    BDropdownItem,
    BDropdownForm,
    BFormInput,
    BInputGroupAppend,
    transactionCreate,
    BPopover,
    BCalendar,
    BFormDatepicker,
    BInputGroup,
    toggleShowDetail,
    TableDetailStartMonthTran,
    BFormSelect
  },
  data() {
    return {
      editSumma: null,
      summaExecution: null,
      currentPopUp: '',
      filterExpNames: '',
      currentYear: null,
      filterContractors: '',
      test: true,
      fields: [
        {key: 'title', label: '', thClass: 'transform-none'},
        {key: 'jan', label: 'янв', thClass: 'thead-cf'},
        {key: 'feb', label: 'фев', thClass: 'thead-cf'},
        {key: 'mar', label: 'мар', thClass: 'thead-cf'},
        {key: 'apr', label: 'апр', thClass: 'thead-cf'},
        {key: 'may', label: 'май', thClass: 'thead-cf'},
        {key: 'jun', label: 'июн', thClass: 'thead-cf'},
        {key: 'jul', label: 'июл', thClass: 'thead-cf'},
        {key: 'aug', label: 'авг', thClass: 'thead-cf'},
        {key: 'sep', label: 'сен', thClass: 'thead-cf'},
        {key: 'oct', label: 'окт', thClass: 'thead-cf'},
        {key: 'nov', label: 'ноя', thClass: 'thead-cf'},
        {key: 'dec', label: 'дек', thClass: 'thead-cf'},
      ],
      selectEditPurchase: null,
      selectedExpenseItems: '',
      currentPage: 1,
      showModal: false
    }
  },

  computed: {
    getGroupedTransactionsTop() {
      if (this.currentYear) return this.topRows[this.currentYear];
      return []
    },

    ...mapState('pnl', [
      'topRows',
      'salesWB',
      'revenueWB',
      'realizedCost',
      'operatingExpenses',
      'transactionsYears',
    ]),
  },

  methods: {
    clearCreatePurchase() {
      this.selectEditPurchase = null;
    },

    getMonthNum(montName) {
      const monthList = {
        'jan': 1,
        'feb': 2,
        'mar': 3,
        'apr': 4,
        'may': 5,
        'jun': 6,
        'jul': 7,
        'aug': 8,
        'sep': 9,
        'oct': 10,
        'nov': 11,
        'dec': 12,
      };
      return monthList[montName]
    },

    addDetailRows(show, row) {
      let names = {
        'salesWB': 'salesWB',
        'revenueWB': 'revenueWB',
        'realizedCost': 'realizedCost',
        'operatingExpenses': 'operatingExpenses',
      };

      let i = this.topRows[this.currentYear].findIndex(item => item.name === row.name) + 1;
      if (this[names[row.name]][this.currentYear]?.length) {
        if (show) this.topRows[this.currentYear].splice(i, 0, ...this[names[row.name]][this.currentYear]);
        else {
          let itemsNames = {[row.name]: true};
          for (let j = 0; j < this.topRows[this.currentYear].length; j++) {
            let item = this.topRows[this.currentYear][j]
            if (item.parent && itemsNames[item.parent]) {
              itemsNames[item.name] = true;
              item.showDetails = false;
              this.topRows[this.currentYear].splice(j, 1);
              j--;
            }
          }
          itemsNames = {};
        }
      }
    },

    resetZIndex(index) {
      const td = document.querySelector(`#expenseItem_td_${index}`);
      td.style.zIndex = 2;
    },

    increaseZIndex(index) {
      const td = document.querySelector(`#expenseItem_td_${index}`);
      td.style.zIndex = 9;
    },

    closeAll() {
      if (this.topRows[this.currentYear].length)
        for (let i = 0; i < this.topRows[this.currentYear].length; i++) {
          let item = this.topRows[this.currentYear][i];
          if ('showDetails' in item) item.showDetails = false
          if (item.parent) {
            this.topRows[this.currentYear].splice(i, 1);
            i--
          }
        }
    },

    ...mapActions('pnl', ["getPnlReport"]),
  },

  watch: {
    currentYear() {
      this.closeAll()
    },
    transactionsYears(val) {
      this.currentYear = val[val.length - 1]
    }
  },
  async mounted() {
    await this.getPnlReport()
  },
}
</script>

<style>
.button-edit-purchase {
  outline: none;
  border: none;
  background: none;
}

.button-set-status {
  padding: 0;
}

.menu-list-expense-item {
  max-height: 300px;
  overflow-y: auto;
}

.detail-group-middle {
  margin-left: 20px;
}

.detail-group-bottom {
  margin-left: 75px;
}

.button-list-expense-item {
  width: 100%;
  padding: 0 !important;
}


.button-list-expense-item-contractor {
  color: #4e5154 !important;
  font-weight: normal
}

.date-create-tr {
  color: #4e5154;
  font-weight: normal
}

.transform-none {
  text-transform: none !important;
  vertical-align: middle !important;
}

.thead-cf {
  text-transform: none !important;
  padding: 0 !important;
}

.table-all-cf {
  min-height: 280px;
}

.button-set-status::after {
  content: none;
}


</style>
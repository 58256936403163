<template>
  <div class="table-cf">
    <b-table
        responsive
        :fields="fields"
        class="detail-table"
        :thead-tr-class="'text-nowrap'"
        :items="groupedTransactionsTop"
    >
      <template #head(feb)="data">
        <div style="">
          {{ data.field.label }}
        </div>
      </template>
      <template #head(mar)="data">
        <div style="">
          {{ data.field.label }}
        </div>
      </template>
      <template #head(apr)="data">
        <div style="">
          {{ data.field.label }}
        </div>
      </template>
      <template #head(may)="data">
        <div style="">
          {{ data.field.label }}
        </div>
      </template>
      <template #head(jun)="data">
        <div style="">
          {{ data.field.label }}
        </div>
      </template>
      <template #head(jul)="data">
        <div style="">
          {{ data.field.label }}
        </div>
      </template>
      <template #head(aug)="data">
        <div style="">
          {{ data.field.label }}
        </div>
      </template>
      <template #head(sep)="data">
        <div style="">
          {{ data.field.label }}
        </div>
      </template>
      <template #head(oct)="data">
        <div style="">
          {{ data.field.label }}
        </div>
      </template>
      <template #head(nov)="data">
        <div style="">
          {{ data.field.label }}
        </div>
      </template>
      <template #head(dec)="data">
        <div style="">
          {{ data.field.label }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>

import {
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
  BPopover,
  BInputGroup,
  BDropdownForm,
  BInputGroupAppend,
  BFormSelect,
  BCalendar,
  BFormInput
} from 'bootstrap-vue';

import {mapActions, mapState} from "vuex";
import transactionCreate from "@/components/econ/TransactionCreate";
import summaResultExecution from "@/components/econ/summaResultExecution";

export default {
  name: "TableDetailStartMonthTran",
  components: {
    BButton,
    BTable,
    BDropdown,
    summaResultExecution,
    BDropdownItem,
    BDropdownForm,
    BFormInput,
    BInputGroupAppend,
    transactionCreate,
    BPopover,
    BCalendar,
    BFormDatepicker,
    BInputGroup,
    BFormSelect
  },
  data() {
    return {

      editSumma: null,
      summaExecution: null,
      currentPopUp: '',
      filterExpNames: '',
      filterContractors: '',
      fields: [
        {key: 'title', label: ''},
        {key: 'jan', label: 'янв'},
        {key: 'feb', label: 'фев'},
        {key: 'mar', label: 'мар'},
        {key: 'apr', label: 'апр'},
        {key: 'may', label: 'май'},
        {key: 'jun', label: 'июн'},
        {key: 'jul', label: 'июл'},
        {key: 'aug', label: 'авг'},
        {key: 'sep', label: 'сен'},
        {key: 'oct', label: 'окт'},
        {key: 'nov', label: 'ноя'},
        {key: 'dec', label: 'дек'}
      ],
      selectEditPurchase: null,
      selectedExpenseItems: '',
      currentPage: 1,
      showModal: false
    }
  },
  methods: {
    clearCreatePurchase() {
      this.selectEditPurchase = null;
    },

    resetZIndex(index) {
      const td = document.querySelector(`#expenseItem_td_${index}`);
      td.style.zIndex = 2;
    },

    increaseZIndex(index) {
      const td = document.querySelector(`#expenseItem_td_${index}`);
      td.style.zIndex = 9;
    },

    async updateSumma({id, summa}) {
      await this.updateTransaction({id, summa});
      this.editSumma = null;
    },

    async updateSummaExecution({id, summaExecution}) {
      await this.updateTransaction({id, summaExecution});
      this.summaExecution = null;
    },

    async addPurchaseToTransaction({id, purchaseId}) {
      await this.updateTransaction({id, procurementId: purchaseId})
    },

    async delTransaction(id) {
      if (id === null) await this.cancelCreate()
      await this.del({id})
    },

    async updateDate({id, dateContext}) {
      await this.updateTransaction({id, date: dateContext.selectedYMD});
    },

    async updateDateExecution({id, dateContext}) {
      await this.updateTransaction({id, dateReceipt: dateContext.selectedYMD});
    },

    async createTransaction({item, field, value}) {
      item[field] = value
      if (field === 'summa') {
        this.editSumma = null;
        await this.create({
          expenseItem: item.expenseItem,
          contractorId: item.contractorId,
          finAccountId: item.finAccountId,
          date: item.date,
          dateReceipt: item.dateExecution,
          summa: item.summa,
        })
      }
    },
    formatDate(dateString) {
      const months = ['янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];
      const [year, month, day] = dateString.split('-');
      const formattedMonth = months[parseInt(month) - 1];
      const formattedYear = year.slice(-2);
      return `${day} ${formattedMonth}. ${formattedYear}`;
    },

    getDate(date) {
      if (date) {
        let date = new Date(date);
        let year = date.getUTCFullYear();
        let month = String(date.getUTCMonth() + 1).padStart(2, '0');
        let day = String(date.getUTCDate()).padStart(2, '0');
        return `${day}.${month}.${year}`;
      }
      return ''
    },

    ...mapActions('econ', ["getTransactions", "getCatalogs", "del", "create", "updateTransaction", "addTempTransaction", "cancelCreate", "create"]),
  },
  props: {
    typeGroup: String
  },
  computed: {
    getTransactionsItems() {
      let typeTran = {
        endMonth: '',
        turnoverMonth: '',
        totalExpenses: '',
        investments: '',
        finActions: '',
        operations: '',
        startMonth: '',
      }
      const item = {
        'title': '',
        'jan': '',
        'feb': '',
        'mar': '',
        'apr': '',
        'may': '',
        'jun': '',
        'jul': '',
        'aug': '',
        'sep': '',
        'oct': '',
        'nov': '',
        'dec': '',
      };

      for (const itemKey in item) {
        for (const tran of this.transactionsByPeriod) {
          if (tran.transaction_month < monthList[itemKey]) {
            item[itemKey] += +tran.total_summa || 0;
          }
        }
      }

      state.groupedTransactionsTop.push(item);

      return this.transactionsByPeriod
    },

    getExpNames() {
      return this.expenseItems.filter(item => {
        if (item.name === null ||
            item.name.toLowerCase().includes(this.filterExpNames.toLowerCase())) return item
      })
    },
    ...mapState('econ', ['transactionsByPeriod', 'groupedTransactionsTop']),
  },
  watch: {},
  async mounted() {
    await this.getCatalogs()
  },
}
</script>

<style>
.button-edit-purchase {
  outline: none;
  border: none;
  background: none;
}

.button-set-status {
  padding: 0;
}

.menu-list-expense-item {
  max-height: 300px;
  overflow-y: auto;
}

.button-list-expense-item {
  width: 100%;
  padding: 0 !important;
}


.button-list-expense-item-contractor {
  color: #4e5154 !important;
  font-weight: normal
}

.date-create-tr {
  color: #4e5154;
  font-weight: normal
}

.transform-none {
  text-transform: none !important;
  vertical-align: middle !important;
}

.button-set-status::after {
  content: none;
}


</style>